<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="RESULT"
      tooltip-title="RESULT"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <div class="flex flex-col gap-5 bg-white p-6 rounded-lg border border-action-bg">
        <div v-for="(detail, key) in resultDetails" :key="key" class="w-full">
          <div v-if="!['id'].includes(key)" class="flex font-roboto font-base">
            <div class="w-48 mr-2 min-w-3">{{ toPascalCase(key) }} :</div>
            <div class="text-menu capitalize">
              {{ detail || detail === 0 ? detail : GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 flex justify-between items-center">
        <Heading heading="Result Summary" class="flex-1" />
        <span class="mt-5">
          <UiSingleSelect
            v-model="currentSelectedMode"
            :hide-title="true"
            label="title"
            :options="resultMods"
          />
        </span>
      </div>
      <div v-if="isEmpty(sectionResultList)">
        <NoRecordFound class="mt-28" />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH v-for="(head, index) in tableHeaders" :key="index">
              {{ head }}
            </TH>
          </TRHead>
        </THead>
        <TBody v-if="currentSelectedMode === 'Section Summary'">
          <TRBody v-for="(result, index) in sectionResultList" :key="index">
            <TD>
              {{ result.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ result.subject_title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ result.total_marks || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>{{ result.passing_percentage }}%</TD>
            <TD>{{ result.passing_students_count }} / {{ result.total_students_count }}</TD>
          </TRBody>
        </TBody>
        <TBody v-else>
          <TRBody v-for="(result, index) in studentResultList" :key="index">
            <TD>
              <SingleUserDisplay :user="result.student" label="full_name" :image="true" />
            </TD>
            <TD>
              {{ result.obtained_marks || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ result.marks_percentage || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ result.grade_title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              <UiStatus :title="result.status" :success="result.status === PASS" />
            </TD>
            <TD>
              <span
                class="text-primary-purple-600 cursor-pointer"
                @click="redirectToStudentResultPage(result.student_section_result_id)"
              >
                View Result
              </span>
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
    </div>
    <Pagination
      v-if="resultCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
      v-show="!isLoading && currentSelectedMode !== 'Section Summary'"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(resultCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filteredRecords"
    />
    <UIConfirmationModal
      v-if="isPublishedConfirmation"
      :modal="isPublishedConfirmation"
      heading="PUBLISHED_RESULT"
      message="Are you sure you want to published "
      button-text="Published"
      :name="resultDetails.title"
      remaining-message="Result ?"
      @cancel="togglePublishedResultModal"
      @confirm="publishedExamHandler"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import { toPascalCase } from '@utils/generalUtil'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import isEmpty from 'lodash/isEmpty'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    NoRecordFound,
    SingleUserDisplay,
    UiSingleSelect,
    UIConfirmationModal,
    Pagination,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },

  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      PASS: 'pass',
      tableHeaders: [],
      isLoading: false,
      resultDetails: {},
      currentSelectedSubject: null,
      resultCounts: 0,
      sectionResultList: [],
      studentResultList: [],
      resultMods: ['Section Summary', 'Student Summary'],
      studentSummaryTableHeaders: [
        'Student',
        'Obtained Marks',
        'Marks Percentage',
        'Grade',
        'Status',
        'Result',
      ],
      sectionSummaryTableHeaders: [
        'Subject Result Title',
        'Subject',
        'Total Marks',
        'Passing Percentage',
        'Pass/Total',
      ],
      currentSelectedMode: 'Section Summary',
      isPublishedConfirmation: false,
    }
  },
  page: {
    title: 'Exam Subject Result',
  },
  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope']),
    currentResultId() {
      return this.$route.params.id
    },
  },
  watch: {
    currentSelectedMode: {
      immediate: true,
      handler(value) {
        this.tableHeaders =
          value === 'Student Summary'
            ? this.studentSummaryTableHeaders
            : this.sectionSummaryTableHeaders
      },
    },
    currentClassScope: {
      handler() {
        this.$router.push({ name: 'subject-result' })
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.$router.push({ name: 'subject-result' })
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredRecords()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    paginationCounts,
    toPascalCase,
    async filteredRecords(range) {
      this.isLoading = true
      let query = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_result_id', this.currentResultId),
        },
      }
      let payload = {
        ...paginationRangeHandler(range),
        ...query,
      }
      let [response, error] = await this.viewSectionGeneratedResult(payload)
      this.resultDetails = this.getDetails(response.data.section_result)
      this.sectionResultList = response.data.section_result_summary
      this.studentResultList = response.data.records
      this.resultCounts = response.data.meta.total_records
      this.studentResultList.forEach((result) => {
        result.obtained_marks = result.obtained_marks.toFixed(2)
        result.marks_percentage = result.marks_percentage.toFixed(2)
        fullName(result.student)
      })
      this.isLoading = false
    },
    redirectToStudentResultPage(resultId) {
      this.$router?.push({
        name: 'student-result-detail',
        params: {
          id: this.currentResultId,
          resultId,
        },
      })
    },
    setRightbarData(isPublished) {
      const rightBarContent = {
        header: {
          title: 'SUBJECT_RESULT',
          buttons: [
            {
              title: isPublished ? 'RESULT_PUBLISHED' : 'PUBLISHED_RESULT',
              classes: ['primary-button-right'],
              disable: !!isPublished,
              action: {
                func: () => {
                  this.togglePublishedResultModal()
                },
              },
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    getDetails(details) {
      return {
        title: details.title,
        gradingStructure: details.non_gpa_grading.title,
        minimumPassingSubjects: details.min_passing_subjects,
        totalMakrs: details.total_marks,
        id: details.id,
      }
    },
    togglePublishedResultModal() {
      this.isPublishedConfirmation = !this.isPublishedConfirmation
    },
    async publishedExamHandler() {
      let id = this.resultDetails.id
      let [response, error] = await this.publishedSectionResult(id)
      if (response) {
        this.filteredRecords()
        this.togglePublishedResultModal()
      }
    },
    ...mapActions('exams', ['viewSectionGeneratedResult', 'publishedSectionResult']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
  .timeBg {
    background-color: var(--bg-white);
  }
}
</style>
